'use strict';

var integrations = require('integrations/main');

integrations.baseFiles.cart = require('./cart');
integrations.baseFiles.footer = require('./components/footer');
integrations.baseFiles.base = require('./product/base');
integrations.baseFiles.meter = require('./product/meter');
integrations.baseFiles.tagManager = require('./components/tagManager');

module.exports = integrations;

