'use strict';

var footer = require('core/components/footer');
var footerBackToTop = footer.backToTop;

footer.methods = footer.methods || {};
/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
footer.methods.displayMessage = function (success, msg) {
    var status = success ? 'alert-success' : 'alert-danger';

    if ($('.email-signup-message-inline').length === 0) {
        $('footer .email-signup-form form').append('<div class="email-signup-message-inline"></div>');
    } else {
        $('.email-signup-message-inline').empty();
    }

    $('.email-signup-message-inline').append('<div class="email-signup-alert alert ' + status + '">' + msg + '</div>');
};


footer.methods.showBackToPlpButton = function () {
	if ($(window).scrollTop() > 100) {
        $('.back-to-top').fadeIn();
    } else {
        $('.back-to-top').fadeOut();
    }
};

footer.backToTop = function () {
    footerBackToTop.apply(this, arguments);

    footer.methods.showBackToPlpButton();

    $(window).scroll(function() {
        footer.methods.showBackToPlpButton();
    });
},

module.exports = footer;
