'use strict';

var coreCart = require('core/cart/cart');
var coreCartInit = coreCart.init;
var coreCartUpdateCartTotals = coreCart.updateCartTotals;
var coreProductBase = require('core/product/base');

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
coreCart.updateProductDetails = function(data, uuid) {
    $('.card .product-info.uuid-' + uuid).parent().replaceWith(data.renderedTemplate);
    coreProductBase.enableQuantitySteppers();
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
coreCart.updateCartTotals = function (data) {
    // trigger AB Core updateCartTotals first
    coreCartUpdateCartTotals.call(this, data);

    var totalCalculated = data.totals.grandTotal.substr(1).replace(/,/g, '');
    $('.affirm-as-low-as').attr('data-amount', (totalCalculated * 100).toFixed());
    affirm.ui.refresh();
}

coreCart.init = function () {
    coreCartInit.apply(this, arguments);

    // cartFernzySlider.isml
    $('body').on('cart:update', function (e, data, uuid) {
        if (data.basket.items.length === 0) {
            window.getHomepageProductsApi?.();
        }
    });
};

module.exports = coreCart;
