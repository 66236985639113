'use strict';

var base = require('integrations/product/base');

base.addToCart = function () {
    var scope = this;

    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;
        var $button = $(this);

        $('body').trigger('product:beforeAddToCart', this);

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            $('.product-detail').each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).find('.product-id').text(),
                        qty: $(this).find('.quantity-select').val(),
                        options: scope.methods.getOptions($(this)),
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }

        pid = scope.methods.getPidValue($(this));

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            var $productModalbody = $(this).closest('.modal-content');
            addToCartUrl = scope.methods.getAddToCartUrl($productModalbody);
        } else {
            addToCartUrl = scope.methods.getAddToCartUrl($productContainer);
        }

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: scope.methods.getChildProducts(),
            quantity: scope.methods.getQuantitySelected($(this)),
        };

        if (!$('.bundle-item').length) {
            form.options = scope.methods.getOptions($productContainer);
        }

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    scope.methods.handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    $('body').trigger('product:afterAddToCartQuickview', data); //cart page quickview only
                    $.spinner().stop();
                    scope.methods.miniCartReportingUrl(data.reportingURL);

                    if (!data.error) {
                        $button.text('Added To Cart');
                        window.showAddToCartStatus?.();
                    }
                },
                error: function () {
                    $.spinner().stop();
                },
            });
        }
    });
};

module.exports = base;
