'use strict';

var debounce = require('lodash/debounce');
var headerUtils = require('../utilities/headerUtils');

var updateHeader = status => {
    var $headerNav = $('.header-nav');
    var headerNavHeight = headerUtils.getHeaderHeightNavOnly();
    var $page = $('.page');

    if (status === 'fixed') {
        $headerNav.addClass('fixed');
        if (!($headerNav.closest('.transparent-header').length)) {
            $page.css('margin-top', headerNavHeight + 'px');
        }
        $headerNav.removeClass('transparent-nav');
    } else {
        $headerNav.removeClass('fixed');
        $page.css('margin-top', '');
        var isMobileOpen = $('body').hasClass('mobile-menu-in');
        if (!isMobileOpen){
            $headerNav.addClass('transparent-nav');
        }
    }
}

module.exports = function() {
    // Fix/unfix header to top of screen, dependent on class added by custom preference
    // Note: This is intentionally not using utilities/scroll.js so that it can fix immediately vs. being debounced.
    if ($('header').hasClass('fixed-header')) {
        $(window).scroll(debounce(event => {
            var preNavHeight = headerUtils.getHeaderHeight('.header-nav');
            var scrollTop = $(event.target).scrollTop();

            updateHeader(scrollTop > preNavHeight ? 'fixed' : 'unfixed');
        }, 10, {leading: true, trailing: true}));
    }

    var isMobile = window.isMobile();
    if ($('header').hasClass('transparent-header') && !isMobile) {
        $(($('.logo-left').length ? '.header-nav' : '.header-nav:not(.secondary-nav)')).hover(
                function() {
                    $(this).removeClass('transparent-nav').find('.secondary-nav').removeClass('transparent-nav');
                    $(this)[$(this).hasClass('secondary-nav') ? 'prev' : 'next']('.header-nav').removeClass('transparent-nav');
                }, function() {
                    $(this).addClass('transparent-nav').find('.secondary-nav').addClass('transparent-nav');
                    $(this)[$(this).hasClass('secondary-nav') ? 'prev' : 'next']('.header-nav').addClass('transparent-nav');
                }
        );
    }

    // Check for presence of content assets in MegaMenu
    var $megamenuDropdown = $('.megamenu > .megamenu-container > .dropdown-menu');

    $megamenuDropdown.each((index, element) => {
        var $megamenu = $(element);
        if ($megamenu.find('.megamenu-asset-1').children().length) {
            $megamenu.addClass('has-asset-1');
        }
        if ($megamenu.find('.megamenu-asset-2').children().length) {
            $megamenu.addClass('has-asset-2');
        }
    });

    // Show/hide content assets on mobile based on category level
    if (isMobile) {
        var $thirdMenuBackBtn = $('.megamenu .menu-subcategories > .dropdown-item'),
            $thirdSubLink = $('.megamenu .menu-subcategories > .dropdown-item > .dropdown-link');

        $thirdSubLink.on('click', function () {
            $('.megamenu.show .menu-subcategories').parent('li').addClass('thirdCatOpen');
        });
        $thirdMenuBackBtn.on('click', function () {
            $('.megamenu.show .menu-subcategories').parent('li').removeClass('thirdCatOpen');
        });
    }

    $('.main-menu .navbar-nav').on('mouseenter mouseleave', function () {
        $(this).toggleClass('nav-item-hover');
    });

    $('.main-menu .navbar-nav:not(.secondary-navbar) .nav-item').on('mouseenter mouseleave', function () {
        if ($('#top-header .header-nav.secondary-nav').length && !this.classList.value.includes('inview')) {
            $('#top-header  .header-nav.secondary-nav').toggleClass('show hide-secondary-nav');
        }
    });

    $('header .site-search').on('mouseenter', event => {
        var $siteSearch = $(event.target).closest('.site-search');
        $siteSearch.addClass('expanded');
    });

    $('header .site-search').on('mouseleave', event => {
        var $siteSearch = $(event.target).closest('.site-search');
        var $input = $siteSearch.find('input');
        // Only collapse if the user isn't typing and hasn't entered text
        if (!$input.is(':focus') && !$input.val()) {
            $siteSearch.removeClass('expanded');
        }
    });

    $('form[name=simpleSearch]').on('submit', event => {
        var $form = $(event.target);
        var $input = $('input[name=q]',$form);
        //keep search from submitting if query is empty
        if ($input.val() == "") {
            $input.focus();
            event.preventDefault();
        }
    });

};
