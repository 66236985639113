'use strict';
window.dataLayer = window.dataLayer || [];

/*
*/
function promotionClick(obj){
    var promoObj = {
        event: 'promotionClick',
        ecommerce: {
            promoClick: {
                promotions: []
            }
        }
    }
    promoObj.ecommerce.promoClick.promotions.push(obj);
    dataLayer.push(promoObj);
}

/**
 * push homepage click analytics events
 * @param {string} eventlabel - Event Label for the event
 * @param {string} eventaction - Event Action for the event
 */
function homePageEvent(eventlabel, eventaction) {
    if (eventlabel && eventaction) {
        dataLayer.push({
            event: 'GAevent',
            eventCategory: 'Homepage Click',
            eventAction: eventaction,
            eventLabel: eventlabel
        });
    }
}

/**
 * push header navigation click analytics events
 * @param {string} eventlabel - Event Label for the event
 * @param {string} eventaction - Event Action for the event
 */
function headerNavigationEvent(eventlabel, eventaction) {
    if (eventlabel && eventaction) {
        dataLayer.push({
            event: 'GAevent',
            eventCategory: 'Header Navigation Click',
            eventAction: eventaction,
            eventLabel: eventlabel
        });
    }
}
/**
 * push footer navigation click analytics events
 * @param {string} eventlabel - Event Label for the event
 * @param {string} eventaction - Event Action for the event
 */
function footerNavigationEvent(eventlabel, eventaction) {
    if (eventlabel && eventaction) {
        dataLayer.push({
            event: 'GAevent',
            eventCategory: 'Footer Navigation Click',
            eventAction: eventaction,
            eventLabel: eventlabel
        });
    }
}

function productClick(gtmData, categoryName){
    window.localStorage.setItem('gtmlist', categoryName);
    if (gtmData && categoryName) {
        dataLayer.push({
            event: 'productClick',
            ecommerce: {
                click: {
                    actionField: {
                        list: categoryName
                    },
                    products: [gtmData]
                }
            }
        });
    }
}

/**
 * @function removeFromCart
 * @description Click event for remove product from cart
 */
function removeFromCart (productObject, quantity) {
    var quantObj = {'quantity': quantity},
        obj = {
            'event': 'removeFromCart',
            'ecommerce': {
                'remove': {
                    'products': []
                }
            }
        };
    obj.ecommerce.remove.products.push($.extend(productObject,quantObj));
    dataLayer.push(obj);
}

var events = {
    all: function () {
        $(document).ready(function () {

            $(document).on('click', 'a.header-link', function () {
                var eventaction = $(this).attr('aria-label');
                headerNavigationEvent('My Account', eventaction);
            });
            $(document).on('click', '.wishlist-link', function () {
                var eventaction = 'Wishlist menu';
                headerNavigationEvent($.trim($(this).attr('title')), eventaction);
            });

            $(document).on('click', '.menu-level-1 .nav-item a.nav-link, .menu-level-1 .nav-item a.dropdown-link', function () {
                var eventaction = 'Main Menu';
                headerNavigationEvent($.trim($(this).text()), eventaction);
            });

            $(document).on('click', '.logo-home', function () {
                headerNavigationEvent(window.location.pathname, 'Main Logo');
            });

            $(document).on('click', '.menu-footer a', function () {
                footerNavigationEvent($.trim($(this).text()), 'Footer menu');
            });

            $(document).on('click', '.social-links a', function () {
                footerNavigationEvent($.trim($(this).attr('aria-label')), 'Social Link');
            });

            $(document).on('click', '.postscript a', function () {
                footerNavigationEvent($.trim($(this).text()), 'Footer Link');
            });

            $(document).on('click', '.back-to-top', function () {
                footerNavigationEvent((window.location.pathname), 'Back to top');
            });


            /* $(document).on('click', '.experience-commerce_layouts-slider .hero-background-link', function () {
                homePageEvent($.trim($(this).parent().find('.h1-alt').text()), 'Hero Slider');
            });

            $(document).on('click', '.experience-commerce_assets-popularCategory a.category-tile', function () {
                homePageEvent($.trim($(this).find('.h2-alt').text()), 'Popular Category');
            });

            $(document).on('click', '.experience-commerce_assets-imageAndText a.image-text-block', function () {
                homePageEvent($.trim($(this).find('.btn-tertiary').text()), 'Image text block');
            });

            $(document).on('click', '.experience-commerce_assets-imageAndText a.btn-tertiary, .experience-commerce_assets-imageAndText a', function () {
                homePageEvent($.trim($(this).text()), 'Image text block');
            });
 */


            $(document).on('click', '.experience-component a' , function(e){
                var id ='';
                var name = '';
                var creative = ''
                if ($(e.currentTarget).parents('.experience-component').hasClass('experience-commerce_assets-assetInclude')){
                    id = $(e.currentTarget).parents('.experience-component').find('.content-asset').attr('data-asset-id');
                    name = 'content-asset';
                } else if ($(e.currentTarget).parents('.experience-component').hasClass('.experience-commerce_assets-heroBanner')){
                    id = 'experience-commerce_assets-heroBanner'
                    name = $.trim($(e.currentTarget).text());
                } else if ($(e.currentTarget).parents('.experience-component').hasClass('.experience-commerce_assets-popularCategory')) {
                    id = 'experience-commerce_assets-popularCategory';
                    name = 'Popular Category';
                    creative = $.trim($(e.currentTarget).find('.h2-alt').text());
                } else if ( $(e.currentTarget).parents('.experience-component').hasClass('.experience-commerce_assets-imageAndText')) {
                    id= "experience-commerce_assets-imageAndText";
                    name = $.trim($(this).text());
                    creative = 'Image text block';
                }
                var obj= {}
                obj.name = name;
                obj.id = id;
                obj.creative = creative;
                promotionClick(obj);
            })

            $('body').on('frenzysearch:productitem', function (e, response) {
                let gtmData = JSON.parse(response);
                if (gtmData) {
                    dataLayer.push({
                        event: 'productImpression',
                        ecommerce: {
                            impressions: gtmData
                        }
                    });
                }
            });

            $('body').on('frenzyRecommadtion:productitem', function (e, response) {
                let gtmData = JSON.parse(response);
                if (gtmData) {
                    dataLayer.push({
                        event: 'productImpression',
                        ecommerce: {
                            impressions: gtmData
                        }
                    });
                }
            });

            $(document).on('click', '.product-click-track', function () {
                let gtmData = JSON.parse($(this).attr('data-gtm-data'));
                let categoryName = gtmData.list;
                if ($(this).parents('.experience-commerce_assets-sliderCategoryProducts').length > 0) {
                    categoryName = 'Home Page';
                }
                productClick(gtmData, categoryName);

            });

            $(document).on('click', '.frenzygtm a', function () {
                let gtmData = JSON.parse($(this).parents('.frenzygtm').attr('data-gtm-data'));
                let categoryName = 'Frenzy Recommendation';
                productClick(gtmData, categoryName);
            });

            $(document).on('click', '.frenzySearchgtm a', function () {
                let gtmData = JSON.parse($(this).parents('.frenzySearchgtm').attr('data-gtm-data'));
                let categoryName = 'Frenzy Search Results';
                productClick(gtmData, categoryName);
            });

            $('body').on('click', '.remove-product', function () {
                var $ele = $(this);
                var gmtData = $ele.data('gtmdata') || $.parseJSON($ele.attr('data-gtmdata'));
                var qty = $ele.data('quantity') || $.parseJSON($ele.attr('data-quantity'));
                $('body').on('click', '#removeProductModal .cart-delete-confirmation-btn', function () {
                    removeFromCart(gmtData, qty);
                });
            });
        });
    }
}

module.exports = events;
